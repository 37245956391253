import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {DatePickerComponent, GridPageComponent, HierarchicalDropdownFieldComponent,} from '@vivli/shared/components';
import {ColDef, GridApi} from 'ag-grid-community';
import {first} from 'rxjs/operators';
import {IDropdownMenuItem, IReportItem, IReportSpec, IStatusUpdate} from '@vivli/shared/infrastructure/interface';
import {useDataRequestsService} from '@vivli/features/data-requests/infrastructure/context';
import {GridCellRendererEnum, ReportDataTypeEnum} from '@vivli/shared/infrastructure/enum';
import {useToastService} from '@vivli/shared/infrastructure/context';
import {useCleanupHook, useQueryParamsHook} from '@vivli/shared/infrastructure/hook';
import {useReportItems} from '@vivli/features/dashboard/infrastructure/hook';
import moment from 'moment';
import {StudyStatusEnum} from '@vivli/features/studies/infrastructure/enum';
import {MetricsReportFilterComponent} from "@vivli/features/dashboard/components";

const customHeaderContainerStyle: CSSProperties = {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minWidth: '400px',
    width: '100%',
};


export const ReportFeature = () => {
    const dataRequestsService = useDataRequestsService();
    const toast = useToastService();
    const noFlag =

        useCleanupHook();

    const {
        reportItems,
        isLoadingReportItems,
        reportSpecs,
        refreshItemsForSpecificReport,
        vivliAdminReport,
        getDataRequestUrlForReportItem,
    } = useReportItems();
    const [selectedReportSpec, setSelectedReportSpec] = useState<IReportSpec>();
    const [reportSpecMenuItems, setReportSpecMenuItems] = useState<IDropdownMenuItem[]>();
    const [generatedColumnDefs, setGeneratedColumnDefs] = useState<ColDef[]>();
    const [dateForCalculations, setDateForCalculations] = useState<Date>();
    const [dataRequestIds, setDataRequestIds] = useState([]);
    const [reportTitle, setReportTitle] = useState('');
    const gridRef = useRef<GridApi>();
    const [selectedOrg, setSelectedOrg] = useState<string>(null)
    const queryParms = useQueryParamsHook();

    const getQueryString = () => {
        let paramArray = [];

        if (dataRequestIds?.length) {
            // Padded with zeros to make it 8 digits
            const paddedIds = dataRequestIds.map((id) => id.padStart(8, '0'))
            paramArray = [
                ...paramArray,
                ...paddedIds.map(id => `dataRequestId=${id}`),
            ];
        }
        return paramArray.length ? paramArray.join('&') : null;
    }

    const saveStatusUpdate = (checked: boolean, data: IReportItem) => {
        const newStatus: IStatusUpdate = {
            ...data.statusUpdate,
            flagRequest: checked,
        };

        dataRequestsService
            .updateDataRequestStatus(data.realId, newStatus)
            .pipe(first())
            .subscribe(
                () => {
                    toast.success('Successfully saved your Status Update', {
                        autoClose: 3000,
                    });
                },
                (e) => {
                    toast.error(
                        `An error occurred submitting your status update.  Please try again or contact Vivli support. Message: ${e.message}`,
                        {autoClose: 3000}
                    );
                }
            );
    };

    // Note: All column specifics for all types of reports are DEFINED
    // in the individual reports files file in the Master Platform repo in:
    // https://dev.azure.com/vivli/Vivli%20Platform/_git/MasterPlatform?path=/src/Vivli.AppConfiguration/static_db_config/simpleObjects/ReportSpecDocument-Files&version=GBdevelopment
    // These are json files used to create the report specs in the database, which are read as configuration
    // items when the app starts up.  The report specs are used to create the column definitions for the reports.
    // To make a change to an existing report, go to the Master Platform repo and pull latest development to
    // create your branch from. To test
    //your change locally before committing it, you can also make the change manually in the
    // Cosmos document that contains the report (select * from c where c.DocumentType = "ReportSpecDocument")
    // When you rerun the app locally, the configuration will contain your changed record. To add a new
    /// report, add a new document in the MasterPlatform repo, following the naming conventions used
    // for the existing reports.
    // Make sure you do PRs for your master platform changes as well as any Vivli Platform changes. The pipeline that
    // applies the Master Platform changes to devint may run automatically when you make these changes; if not, you'll
    // have to run it (see directions in the Tools.Utility area). After the pipeline runs you'll need to restart
    // services for the App Config on Devint to pick up the changes.
    const generateColumnDefs = (): ColDef[] => {
        const genColumns: ColDef[] = [];
        const reportCol = selectedReportSpec.reportColumns;
        reportCol.forEach(function (c) {
            const col: ColDef = {
                filter: true,
                sortable: true,
                wrapText: true,
                cellRenderer: null,
                cellRendererParams: null,
                valueGetter: null,
            };
            col.headerName = c.columnTitle;
            col.field = c.columnPropertyName;
            col.width = c.width > 0 ? c.width : null;
            if (c.cellRenderer) {
                col.cellRenderer = mapCellRendererTypes(c.cellRenderer.toString());
            }
            if (c.cellRendererParams) {
                col.cellRendererParams = c.cellRendererParams;
            }
            if (col.headerName.length > 12) {
                col.headerTooltip = col.headerName;
            }
            if (c.showLocalDate) {
                col.valueGetter = function (params) {
                    const localizedDate = getLocalDate(params.data[col.field]);
                    return localizedDate;
                }
            }

            if (genColumns.length === 0) {
                //first item has to have pinned attribute
                //forcing the field name (it's always VivliID)
                if (selectedReportSpec.dataType === ReportDataTypeEnum.DataRequest) {
                    col.field = 'vivliId';
                    col.width = 120;
                }
                col.pinned = 'left';
                col.wrapText = false;
                col.comparator = (valueA, valueB) => valueA - valueB
            }
            genColumns.push(col);
        });
        // the last column is hardcoded - vivli admins in non-calc reports only
        if (selectedReportSpec.userType === 'VivliAdmin'
            && !selectedReportSpec.isForMetricsCalculations
            && selectedReportSpec.dataType === ReportDataTypeEnum.DataRequest) {
            genColumns.push({
                field: 'statusUpdate.flagRequest',
                headerName: 'Flag',
                filter: true,
                sortable: true,
                width: 140,
                cellRenderer: GridCellRendererEnum.GridCheckbox,
                cellRendererParams: {onChange: saveStatusUpdate},
            });
        }

        return genColumns;
    };

    const mapCellRendererTypes = (reportSpecRendererDef: string): GridCellRendererEnum => {
        switch (reportSpecRendererDef) {
            case 'GridCellList':
                return GridCellRendererEnum.GridCellList;
            case 'GridCellLargeText':
                return GridCellRendererEnum.GridCellLargeText;
            case 'GridCellDateTime':
                return GridCellRendererEnum.GridCellDateTime;

        }
    };

    const handleRowClick = (data: IReportItem) => {
        let urlToOpen = null;
        switch (selectedReportSpec.dataType) {
            case ReportDataTypeEnum.DataRequest:
                urlToOpen = getDataRequestUrlForReportItem(data);
                break;
            case ReportDataTypeEnum.DataSet:
                if (data.status === StudyStatusEnum.Draft) {
                    urlToOpen = `/admin/studies/submitStudy/${data.realId}/details`;
                } else {
                    urlToOpen = `/admin/studies/${data.realId}/admin-study-feature`;
                }
                break;
            case ReportDataTypeEnum.ListingRequest: {
                urlToOpen = `/study-submission/${data.realId}/InformationAboutYourTeam`;
                break;
            }
            case ReportDataTypeEnum.Enquiry: {
                urlToOpen = `/admin/enquiry/${data.realId}`;
                break;
            }
            case ReportDataTypeEnum.DuaDetail:
                urlToOpen = '/admin/requests/AwaitingMyAction/';
                // the ideal form for the drilldown has not been created yet so the link here is subject to further review
                //urlToOpen = getDataRequestUrlForReportItem(data);
                break;
        }

        window.open(urlToOpen);
    };

    const getLocalDate = (date: Date) => {
        const returnVal = date
            ? moment(date).local().format('YYYY-MM-DD hh:mm A')
            : null;
        return returnVal;
    }

    const reportNameFunction = (reportName) => {
        let title;
        if (reportName.split(',')[1]) {
            title = reportName.split(',')[0];
        } else {
            title = reportName.split('(')[0];
        }
        if (queryParms["nctId"]) {
            title = title.trim().replace(' ', '_') + ` for NCT Id ${queryParms["nctId"]}`;
        }
        setReportTitle(title.trim().replace(' ', '_'));
    }

    ///We refresh when the dataType changes, when we go from non-metrics to metrics,
    ///and when we change a data request report from 1 request per row to
    ///1 requested study per row or vice versa
    const handleReportTypeChanged = (value: any) => {
        if (selectedReportSpec && selectedReportSpec.dataType === value.dataType
            && selectedReportSpec.reportGenerationType === value.reportGenerationType
            && selectedReportSpec.isForMetricsCalculations === value.isForMetricsCalculations) {
            setSelectedReportSpec(value);
            return;
        }

        if (value.isForMetricsCalculations === true) {
            refreshItemsForSpecificReport(value.dataType, value.code, null, selectedOrg, getQueryString());
        } else if (value.dataType === ReportDataTypeEnum.Enquiry && (queryParms["nctId"])) {
            refreshItemsForSpecificReport(value.dataType, value.code, null, queryParms["nctId"]);
        } else {

            refreshItemsForSpecificReport(value.dataType, value.code);
        }

        setSelectedReportSpec(value);
        reportNameFunction(value.name);
    };

    const handleDateChanged = (d: Date) => {
        setDateForCalculations(d);
        refreshItemsForSpecificReport(selectedReportSpec.dataType, selectedReportSpec.code, d);
    };

    const handleContributorChanged = (option: any) => {
        const thisOrg = option[0].value;
        if (thisOrg === 'all') {
            setSelectedOrg(null)
        } else {
            setSelectedOrg(thisOrg);
        }
        const orgValue = thisOrg === 'all' ? null : thisOrg;

        if (selectedReportSpec.isForMetricsCalculations) {
            refreshItemsForSpecificReport(selectedReportSpec.dataType, selectedReportSpec.code, dateForCalculations, orgValue, getQueryString());
        }
    };

    useEffect(() => {
        if (!reportSpecs) {
            return;
        }
        const activeReportSpecs = reportSpecs.filter((a) => a.active);
        const menuItems = activeReportSpecs.map(
            ({
                 name, code, dataType, active, category, accessType, userType,
                 reportColumns, reportGenerationType, isForMetricsCalculations
             }) => ({
                value: {
                    name,
                    code,
                    dataType,
                    active,
                    category,
                    accessType,
                    userType,
                    reportColumns,
                    reportGenerationType,
                    isForMetricsCalculations
                },
                title: name,
            })
        );
        setReportSpecMenuItems(menuItems);
        if (!selectedReportSpec) {
            //set the default report type
            if (queryParms["reportType"]) {
                const sel = reportSpecs.find((a) => a.dataType === queryParms["reportType"]);
                handleReportTypeChanged(sel);
            } else {
                const sel = reportSpecs.find((a) => a.default);
                setSelectedReportSpec(sel);
                reportNameFunction(sel.name);
            }
        }
    }, [reportSpecs]);

    useEffect(() => {
        gridRef.current?.refreshCells();
    }, [reportItems]);

    const buildCustomHeader = (reportSpecs) => {
        if (reportSpecs) {
            const dateToUse = dateForCalculations ?? moment().toDate();
            return (
                <div style={customHeaderContainerStyle}>
                    {vivliAdminReport && (
                        <>
                            <MetricsReportFilterComponent
                                setDataRequestIds={setDataRequestIds}
                                handleContributorChanged={handleContributorChanged}/>
                            <DatePickerComponent
                                style={{
                                    paddingRight: '10px',
                                    marginRight: '10px',
                                    width: '25%',
                                    height: "90px"
                                }}
                                defaultValue={dateToUse}
                                onChange={handleDateChanged}
                                label={'Billing Period Effective Date (Click to Change)'}
                            />
                        </>
                    )}
                    <HierarchicalDropdownFieldComponent
                        style={{width: '45%', height: "90px"}}
                        items={reportSpecMenuItems}
                        sort={'asc'}
                        disabled={isLoadingReportItems}
                        label={'Available Report Types'}
                        defaultValue={selectedReportSpec}
                        objectKey={'name'}
                        onChange={(value) => handleReportTypeChanged(value as IReportSpec)}
                    />
                </div>
            );
        }
    };

    useEffect(() => {
        if (!selectedReportSpec) {
            return;
        }
        const generatedColumns = generateColumnDefs();
        gridRef.current?.setColumnDefs([]);
        gridRef.current?.setColumnDefs([...generatedColumns]);
    }, [selectedReportSpec]);

    useEffect(() => {
        if (isLoadingReportItems) {
            gridRef.current?.showLoadingOverlay();
        } else {
            gridRef.current?.hideOverlay();
        }
    }, [isLoadingReportItems, generatedColumnDefs]);

    return (
        <GridPageComponent
            title="Report"
            columnDefs={generatedColumnDefs}
            rowData={reportItems}
            onRowClick={handleRowClick}
            exportTitle={reportTitle + "_Report"}
            customHeader={buildCustomHeader(reportSpecs)}
            onGridReady={(gridApi) => (gridRef.current = gridApi)}
            flushPrevious={true}
        />
    );
};
