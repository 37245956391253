﻿import React, {CSSProperties, useEffect, useState} from "react";
import {useDataPackageService} from "@vivli/shared/features/data-package/infrastructure/context";
import {useParams} from "react-router";
import {first} from "rxjs/operators";
import {IDataPackage} from "@vivli/shared/features/data-package/infrastructure/interface";
import {
    LinkFieldComponent,
    LoadIndicatorCenteredComponent,
    TextAreaReadOnlyFormComponent,
    TextFieldReadOnlyFormComponent,
} from "@vivli/shared/components";
import {Size, Color, Styles} from "@vivli/shared/theme";
import {ViewDataPackageRowComponent} from "./view-data-package-row.component";
import {ViewDataPackageStudyReferenceComponent} from  "./view-data-package-study-reference.component";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {useTranslation} from "react-i18next";
import {TranslationKeyEnum} from "@vivli/shared/infrastructure/enum";
import {DTICommonConst, DTICTFormFields} from "@vivli/shared/infrastructure/constants";

const outerContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
    overflowY: 'auto',
    height: '100%',
    backgroundColor: Color.LIGHT_GRAY,
    paddingTop: Size.INSET_SIZE,
}
const titleContainerStyle: CSSProperties = {
    ...Styles.FORM_ROW,
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Bold
}
const secondTitleContainerStyle: CSSProperties = {
    ...titleContainerStyle,
    marginTop: 20
}
const textFieldStyle: CSSProperties = {
    flex: '1 1 100%',
    maxWidth: '100%',
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'default',
}

const tombstoneStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    paddingBottom: '20px'
}

export const ViewDataPackageComponent = () => {
    const {dataPackageDoi} = useParams();
    const dataPackageService = useDataPackageService();
    const studyService = useStudiesService()
    const [dataPackage, setDataPackage] = useState<IDataPackage>(null);
    const [study, setStudy] = useState<IStudy>(null);
    const [hasError, setHasError] = useState(false);
    const {t} = useTranslation();

    const header = dataPackage?.title ? ('Vivli Digital Object Identifier Information Sheet: Data Package DOI') : '';
    const documentHeader = dataPackage?.title ? 'This data package contains the following types of documents:' : '';
    const versionNumber = dataPackage?.versionNumber?.toString() ?? '0';
    const studyString = t(TranslationKeyEnum.study)
    const studyDescription = `This data package is part of ${studyString}:`
    const errorText = `The URL or related ${studyString} specified in the browser cannot be found. Please check the URL.`
    const isTombstone = study !== null && dataPackage !== null && study.alternateDataVersions.length > 0 && study.alternateDataVersions.indexOf(dataPackage.id) > -1;
    const tombstoneText = 'This version of the data is no longer available for requesting on the Vivli platform. Follow the link in the field "This data package is part of ..."  below to see if it has been replaced by a more recent version.'

    const handleGetDataPackage = () => {
        dataPackageService.getForAnonymousUser(dataPackageDoi)
            .pipe(first())
            .subscribe((dataPackage) => {
                setDataPackage(dataPackage);
            }, () => setHasError(true))
    }

    const handleGetAssociatedStudy = (id) => {
        studyService.getStudyAnonymous(id)
            .pipe(first())
            .subscribe((study) => {
                setStudy(study);
            }, () => setHasError(true))
    }

    useEffect(() => {
        handleGetDataPackage();
    }, [dataPackageDoi])

    useEffect(() => {
        if (!dataPackage){
            return;
        }
        handleGetAssociatedStudy(dataPackage.studyId);
    }, [dataPackage])

    if (hasError){
        return(
            <div style={titleContainerStyle}>
                {errorText}
            </div>
        )
    }

    if (!dataPackage){
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={outerContainerStyle} className='scrolly'>
            <div style={titleContainerStyle}>
                {header}
            </div>
            {isTombstone && <div style={tombstoneStyle}>
                {tombstoneText}
            </div>
            }

            <TextAreaReadOnlyFormComponent
                label={'Data Package Name'}
                style={textFieldStyle}
                defaultValue={dataPackage?.title}
                rows={3}
                dataId={DTICTFormFields.PackageName}
            />
            <TextFieldReadOnlyFormComponent
                label={'Data Package Version Number'}
                style={textFieldStyle}
                defaultValue={versionNumber}
                dataId={DTICTFormFields.PackageNumber}
            />
            <LinkFieldComponent
                label='Data Package Doi'
                linkContent={dataPackage?.dataPackageDoi }
                linkTitle={dataPackage?.dataPackageDoi }
                style={textFieldStyle}
                dataId={DTICommonConst.HeaderApprovalButton('PackageDoi')}
            />
            <div>
                <div style={secondTitleContainerStyle}>
                    {studyDescription}
                </div>
                <div style={Styles.FORM_ROW}>
                    <ViewDataPackageStudyReferenceComponent study={study}/>
                </div>
            </div>

            <div style={secondTitleContainerStyle}>
                {documentHeader}
            </div>

            {(dataPackage?.dataPackageFileDescriptors?.map((s, index) => (
                <div key={index} style={Styles.FORM_ROW}>
                    <ViewDataPackageRowComponent descriptor={s} />
                </div>
            )))}
        </div>
    )
}
