import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {
    IDataRequest,
    IInProcessDataRequest,
    IRequestedStudy
} from "@vivli/features/data-requests/infrastructure/interface";
import {
    AddStudyInProcessComponent,
    AddUnlistedStudyModalComponent,
    RequestedStudiesComponent
} from "@vivli/features/studies/components";
import {
    ButtonComponent,
    ExportCsvButtonComponent,
    FieldHeaderComponent,
    LoadIndicatorCenteredComponent,
    QuestionMarkComponent
} from "@vivli/shared/components";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useDataRequestContext, useDataRequestsService} from '@vivli/features/data-requests/infrastructure/context';
import {useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {useCsvExportHook} from "@vivli/shared/infrastructure/hook";
import {RequestedStudyTypeEnum, StudyRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useDataPackageFileService} from "@vivli/shared/features/data-package/infrastructure/context";
import {first} from "rxjs/operators";
import {useModalService, useToastService} from "@vivli/shared/infrastructure/context";
import {Color, Size} from "@vivli/shared/theme";
import {useRequestedStudyPermissions} from "@vivli/features/studies/infrastructure/hook";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum, TranslationKeyEnum} from "@vivli/shared/infrastructure/enum";
import {useTranslation} from "react-i18next";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {IModalConfirmOptions} from "@vivli/shared/infrastructure/interface";
import {useNavigate} from "react-router-dom";
import {DTIRequestedStudiesFeature} from "../../../../../../apps/vivli-e2e/src/support/data-test-id.constants";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Size.INSET_SIZE,
}

const exportButtonStyle: CSSProperties = {
    position: 'relative',
    height: 'auto',
    right: 0
}

const addStudyContainerStyle: CSSProperties = {marginLeft: '20px'}

const gridContainerStyle: CSSProperties = {marginLeft: 50}

const sectionStyle: CSSProperties = {marginBottom: 20}

const sectionStyleWithTop: CSSProperties = {marginBottom: 20, marginTop: 40};

const headerContainerStyle: CSSProperties = {display: 'flex', flexDirection: 'row'}

const flexContainerStyle: CSSProperties = {display: 'flex'}

const addStudyButtonStyle: CSSProperties = {
    height: '21px',
    padding: '0',
    paddingRight: '0',
    minWidth: '70px',
    backgroundColor: Color.WHITE,
    borderColor: '#A0A0A0',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#A0A0A0',
}

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    marginLeft: '20px'
}

interface RequestedStudiesFeatureProps {
    dataRequest: IDataRequest;
    requestedStudies: IRequestedStudy[];
    editable?: boolean;
    onChange?: (dataRequest: IDataRequest) => void;
}

export const RequestedStudiesFeature = (
    {
        dataRequest,
        editable,
        onChange,
        requestedStudies
    }: RequestedStudiesFeatureProps
) => {
    const isPreppingZipRef = useRef(false);
    const [zipFileUri, setZipFileUri] = useState<string>(null);
    const dataPackageFileService = useDataPackageFileService();
    const {requestedStudyIpdIsDownloadable, userIsDuaValidated} = useRequestedStudyPermissions();
    const {userIsOwner, isDuaValidatedRequest, userIsDataContributor, isInProcessRequest} = useDataRequestPermissions();
    const toastService = useToastService();
    const dataRequestsService = useDataRequestsService();
    const user = useActiveUser();
    const {exportRows} = useCsvExportHook();
    const modalService = useModalService();
    const {t} = useTranslation();
    const assignedAppType = useAssignedAppType();
    const {organizations} = useOrganizations();
    const [inProcessDataRequest, setInProcessDataRequest] = useState<IInProcessDataRequest>(null);
    const {updateDataRequest} = useDataRequestContext();//.updateDataRequest;
    const navigate = useNavigate();

    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const uppercaseStudiesString = t(TranslationKeyEnum.studies).toUpperCase();
    const noStudiesFound = <div>No {t(TranslationKeyEnum.studies)} Found</div>;

    const requestedStudiesHaveIpdAvailable = dataRequest.requestedStudies.some(r=> requestedStudyIpdIsDownloadable(r));
    const anyDownloadableRequestedStudies = dataRequest.requestedStudies.some(r=> r.downloadableRequestedStudyIPDDataPackage);
    const studyName = t(TranslationKeyEnum.study);
    const successMessage = `${studyName} added to In-Process Data Request`;
    const userHasDuaValidation = userIsDuaValidated(dataRequest);
    const ipdDownloadAllowed = isDuaValidatedRequest(dataRequest) &&
        !userIsDataContributor(dataRequest, organizations) && // data contributors are ALLOWED to download IPD, but are interested in the list, not the IPD
        requestedStudiesHaveIpdAvailable && // at least one study has IPD available
        anyDownloadableRequestedStudies && // at least one study has IPD that is downloadable
        userHasDuaValidation; // user has DUA validation (no downloads without it!)
    const isVivliAdmin = user?.isVivliAdmin;
    const canAddInProcess = isVivliAdmin && isInProcessRequest(dataRequest);

    const downloadStudyFiles = () => {
        if (!ipdDownloadAllowed) {
            toastService.info('Data not yet available.');
        }

        if (zipFileUri) {
            window.open(zipFileUri);
        } else {
            isPreppingZipRef.current = true;
            toastService.info('Preparing zip file for download...please click Download again if required',
                {autoClose: false});
        }

        const sub = dataPackageFileService.downloadDataRequestStudyFiles(dataRequest.id)
            .pipe(first(fileUri => fileUri?.length > 0))
            .subscribe(fileUri => {
                if (fileUri?.length <= 0) {
                    toastService.dismiss();
                    toastService.info('No zip file available for download.')
                    return;
                }
                setZipFileUri(fileUri);
            });
    }

    const downloadCsv = () => {
        const exportData = requestedStudies.map((study: IRequestedStudy) => ({
            sponsorId: study.sponsorProtocolId,
            studyId: study.nctId,
            hasUploadedIpd: study.studyRequestStatus === StudyRequestStatusEnum.IPDProvided,
            title: study.studyTitle,
            pi: study.principalInvestigatorName,
            sponsorName: study.sponsorName,
            contributorName: study.organizationName,
            irpName: study.approvalBodyOrgName,
            requestId: dataRequest.doiStem
        }));

        const filename = 'RequestedStudies.csv';
        const headers = [
            'Sponsor ID',
            'Study Id',
            'Ipd Uploaded',
            'Study Title',
            'Principal Investigator',
            'Sponsor Name',
            'Data Contributor Name',
            'IRP/Approver Name',
            'Data Request Id'
        ]

        exportRows(filename, exportData, headers);
    }

    const addUnlistedStudy = () => {
        const modalId = modalService.custom(<AddUnlistedStudyModalComponent
            dataRequestId={dataRequest.id}
            onClose={() => modalService.dismiss(modalId)}
            onStudyAdded={onChange}
            isInProcess={canAddInProcess}
        />);
    }

    const addInProcessStudy = (id: string) => {
        const inProcess = dataRequestsService
            .addStudyToDataRequestInProcess({studySponsorIdOrNctId: id, dataRequestId: dataRequest.id})
            .pipe(first())
            .subscribe(inProcRequest => {
                setInProcessDataRequest(inProcRequest)
                onChange(dataRequest);
            });
    };

        if (!requestedStudies) {
            return <LoadIndicatorCenteredComponent/>
        }

        const externalStudies = requestedStudies?.filter(s => s.requestedStudyType === RequestedStudyTypeEnum.External);
        const unlistedStudies = requestedStudies?.filter(s => s.requestedStudyType === RequestedStudyTypeEnum.Unlisted);
        const listedStudies = requestedStudies?.filter(s => s.requestedStudyType === RequestedStudyTypeEnum.Listed);
        const showQuestionMarkIcon = () => {
            if (!isAmr) {
                return <QuestionMarkComponent
                    size={25}
                    onClick={() => window.open('https://vivli.org/requested-study-types/', '_blank')}
                />
            }
        };

        useEffect(() => {
            if (!inProcessDataRequest) {
                return
            }
            //check the status message of the in process data request
            if (inProcessDataRequest.item2 !== 'Success') {
                //error display
                modalService.error(inProcessDataRequest.item2);
                toastService.error(inProcessDataRequest.item2)
            } else {
                //success display and action
                const options : IModalConfirmOptions = {
                    hideCancel: true,
                    confirmText: 'OK',
                    onConfirm: () => {
                        navigate(`/admindatarequests/active/${inProcessDataRequest.item1.id}/Studies`);
                    }
                };
                modalService.confirm(`${successMessage}`, options);
                toastService.success(`${successMessage}`);
                //update the context
                updateDataRequest(inProcessDataRequest.item1);
            }
        }, [inProcessDataRequest]);

        return (
            <div style={containerStyle}>
                <div style={sectionStyle}>
                    <div style={headerContainerStyle}>
                        <FieldHeaderComponent title={`REQUESTED ${uppercaseStudiesString}`}/>

                        <div style={buttonContainerStyle}>
                            {/* TODO this url will need to have both an amr and study version once it's built on the vivli site */}
                            {showQuestionMarkIcon()}

                            <ExportCsvButtonComponent
                                onClick={ipdDownloadAllowed ? downloadStudyFiles : downloadCsv}
                                title={ipdDownloadAllowed ? `Download all ${t(TranslationKeyEnum.studies)} available for download as a single zip file` :
                                    `Download a list of the ${t(TranslationKeyEnum.studies)} in this request`}
                                style={exportButtonStyle}
                            />
                        </div>
                    </div>

                    <div style={sectionStyle}/>
                    <div style={sectionStyle}>VIVLI-LISTED AND PROVISIONED {uppercaseStudiesString}</div>
                    <div style={gridContainerStyle}>
                        {listedStudies.length > 0 ? (
                            <RequestedStudiesComponent
                                requestedStudies={listedStudies}
                                dataRequest={dataRequest}
                                onChange={onChange}
                            />
                        ) : noStudiesFound}
                    </div>
                </div>
                <div style={sectionStyle}>
                    <div style={sectionStyle}>VIVLI-LISTED {uppercaseStudiesString} PROVISIONED BY EXTERNAL PROVIDERS
                    </div>
                    <div style={gridContainerStyle}>
                        {externalStudies.length > 0 ? (
                            <RequestedStudiesComponent
                                requestedStudies={externalStudies}
                                dataRequest={dataRequest}
                                onChange={onChange}
                            />
                        ) : noStudiesFound}
                    </div>
                </div>

                {!isAmr &&
                    <>
                        <div style={sectionStyle}>
                            <div style={flexContainerStyle}>
                                <div>STUDIES, DATA OR TOOLS NOT LISTED ON VIVLI</div>

                                {editable && (!isVivliAdmin || userIsOwner(dataRequest)) && (
                                    <div  data-test-id={DTIRequestedStudiesFeature.AddPlus} style={addStudyContainerStyle}>
                                        <ButtonComponent dataId={DTICommonConst.AddButton} onClick={addUnlistedStudy} style={addStudyButtonStyle}>Add
                                            +</ButtonComponent>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={gridContainerStyle}>
                            {unlistedStudies.length > 0 ? (
                                <RequestedStudiesComponent
                                    requestedStudies={unlistedStudies}
                                    dataRequest={dataRequest}
                                    onChange={onChange}
                                />
                            ) : noStudiesFound}
                        </div>
                    </>
                }

                {canAddInProcess &&
                    <div style={sectionStyle}>
                        <div style={sectionStyleWithTop}>{uppercaseStudiesString} TO ADD TO IN-PROCESS DATA REQUEST
                        </div>
                        <AddStudyInProcessComponent
                            studyName={uppercaseStudiesString}
                            isAmr={isAmr}
                            addUnlistedStudy={addUnlistedStudy}
                            addInProcessStudy={addInProcessStudy}
                        />
                    </div>
                }
            </div>
        )
}
